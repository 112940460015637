import React from "react"
import { Link } from "gatsby"
import Select from '../components/timezoneSelect'

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
  </Layout>
)

export default SecondPage
